import Path from "components/Path";
import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import goodDayMainImage from 'img/goodDay-main.png';
import goodDayJewelRunImage from 'img/goodDay-jewelRun.png';
import goodDayGatesImage from 'img/goodDay-gates.png';
import goodDayFireworksImage from 'img/goodDay-fireworks.gif';
import goodDayTreeImage from 'img/goodDay-tree.gif';
import goodDayTackleImage from 'img/goodDay-tackle.gif';
import goodDayGraphicsImage from 'img/goodDay-running.png';

const Container = styled.div``;

const PresentationImage = styled.div`
  background-image: url(${goodDayMainImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 930px;
  margin-top: 20px;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    height: 500px;
  }
  @media only screen and (max-width: 730px) {
    height: 400px;
  }
  @media only screen and (max-width: 580px) {
    height: 200px;
  }
`;

const Line = styled.hr`
  border: 1px solid #FAF5D8;
  margin-bottom: 40px;

`;

const MultiplayerPresentation = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SteamLink = styled.span`
  font-size: 30px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const MultiplayerImage = styled.img`
  display: block;
  width: 40%;
`;

const Title = styled.div`
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
  @media only screen and (max-width: 580px) {
    font-size: 18px;
  }
  text-align: center;
`;

const JewelRunContainer = styled.div`
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JewelRunImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 40%;
  max-height: 550px;
  @media only screen and (max-width: 900px) {
    width: 80%;
    margin-left: 0px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Description = styled.div`
  margin: 0px 10% 0px 10%;
  font-size: 15px;
  @media only screen and (max-width: 580px) {
    font-size: 12px;
  }
`;

const List = styled.ul`
  padding-left: 0px;
  text-align: center;
  list-style-type: none;
`;

const Element = styled.li`
  text-align: center;
  padding: 5px;
`;

const GraphicsContainer = styled.div`
  margin-bottom: 40px;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GraphicsImage = styled.img`
  object-fit: contain;
  object-position: left;
  width: 45%;
  max-height: 550px;
  @media only screen and (max-width: 900px) {
    width: 80%;
  }
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 480px;
  width: 853px;
  @media only screen and (max-width: 853px) {
    width: 90%;
    padding-bottom: 50.64%;
  }
`

const Iframe = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`

const VideoContainer = styled.div`
  margin-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
`;

const handleClick = (link) => {
  window.open(link, '_blank');
};

const GoodDay = ({ t, i18n }) => {
  return (
    <Container>
      <Path
        segments={[{ name: 'Home', path: '/' }]}
        lastSegment="Jewel Run"
      />
      <PresentationImage />
      <Line/>
      <VideoContainer>
        <Video>
          <Iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/0EukPPdzYJg?si=JBNS0OuU4Fop6KT4"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </Video>
      </VideoContainer>
      <SteamLink
        onClick={(e) =>
          handleClick(
            'https://store.steampowered.com/app/2097850/A_Good_Day/'
          )
        }
      >
      {t('goodday_steam_link')}
      </SteamLink>
      <DescriptionContainer>
        <MultiplayerPresentation>
          <JewelRunImage src={goodDayFireworksImage} />
          <JewelRunImage src={goodDayTreeImage} />
        </MultiplayerPresentation>
        <Title>{t('goodday_about_title')}</Title>
        <Description>
          <List>
            <Element>{t('goodday_about_1')}</Element>
            <Element>{t('goodday_about_2')}</Element>
            <Element>{t('goodday_about_3')}</Element>
            <Element>{t('goodday_about_4')}</Element>
            <Element>{t('goodday_about_5')}</Element>
            <Element>{t('goodday_about_6')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <JewelRunContainer>
        <JewelRunImage src={goodDayTackleImage}></JewelRunImage>
      </JewelRunContainer>
      <DescriptionContainer>
        <Title>{t('goodday_fun_title')}</Title>
        <Description>
          <List>
            <Element>{t('goodday_fun_1')}</Element>
            <Element>{t('goodday_fun_2')}</Element>
            <Element>{t('goodday_fun_3')}</Element>
            <Element>{t('goodday_fun_4')}</Element>
            <Element>{t('goodday_fun_5')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <GraphicsContainer>
        <JewelRunImage src={goodDayGraphicsImage}></JewelRunImage>
      </GraphicsContainer>
    </Container>
  );
}

export default withTranslation()(GoodDay);

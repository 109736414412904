import styled from 'styled-components';
import { FaDiscord, FaYoutube } from 'react-icons/fa';
import { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { withTranslation } from 'react-i18next';
import { DARK_SCHEME_COLOR } from 'utility/css/values';
import { useNavigate } from 'react-router';

import logo from 'img/LogoCerbereGames.png';

const MAX_INLINE_CATEGORIES = '700px';

const Container = styled.footer`
  display: flex;
  width: 100%;
  position: relative;
`;

const FooterContainer = styled.div`
  background-color: #435262;
  display: flex;
  width: 100%;
  justify-content: center;
  color: ${DARK_SCHEME_COLOR};
`;

const ContentContainer = styled.div`
  display: flex;
  margin-top: 60px;
  margin-bottom: 60px;
  @media only screen and (max-width: ${MAX_INLINE_CATEGORIES}) {
    width: 100%;
    justify-content: center;
    margin-bottom: 0px;
  }
`;

const Information = styled.div`
  display: flex;
  @media only screen and (max-width: ${MAX_INLINE_CATEGORIES}) {
    width: 80%;
    flex-direction: column-reverse;
  }
`;

const Logo = styled.img`
  height: 140px;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 20px;
  font-size: 30px;
  transition: 0.2s ease;
  color: #192530;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      color: #202e3e;
    }
  }
`;

const Category = styled.div`
  margin-right: 100px;
  @media only screen and (max-width: 900px) {
    margin-right: 50px;
  }
  @media only screen and (max-width: ${MAX_INLINE_CATEGORIES}) {
    margin-bottom: 20px;
    width: 100%;
    border-bottom: ${(props) => (props.canHide ? '1px solid black' : 'none')};
    border-top: ${(props) => (props.canHide ? '1px solid black' : 'none')};
    align-items: ${(props) => (props.canHide ? 'inherit' : 'center')};
    padding-top: ${(props) => (props.canHide ? '10px' : '0')};
    padding-bottom: ${(props) => (props.canHide ? '5px' : '0')};
    text-align: ${(props) => (props.canHide ? 'inherit' : 'center')};
  }
`;

const CategoryItems = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${MAX_INLINE_CATEGORIES}) {
    align-items: inherit;
    display: ${(props) => (props.isHidden ? 'none' : 'flex')};
  }
`;

const ShowCategory = styled.div`
  display: none;
  @media only screen and (max-width: ${MAX_INLINE_CATEGORIES}) {
    display: flex;
  }
`;

const CategoryTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CategoryText = styled.h1`
  font-size: 15px;
  font-weight: 100;
  margin-bottom: 30px;
  @media only screen and (max-width: ${MAX_INLINE_CATEGORIES}) {
    text-align: ${props => props.type === "contact" ? 'center' : 'inherit'};
  }
`;

const TextContainer = styled.div`
  margin-bottom: 10px;
  font-size: 14px;
`;

const Mail = styled.a`
  font-size: inherit;
  font-weight: 100;
  color: inherit;
  text-decoration: none;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Link = styled.span`
  font-size: inherit;
  font-weight: 100;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Footer = ({ t, i18n }) => {
  const [isAboutHidden, setIsAboutHidden] = useState(true);

  const lang = i18n.language;

  const handleClick = (link) => {
    window.open(link, '_blank');
  };

  const navigate = useNavigate();

  return (
    <Container>
      <FooterContainer>
        <ContentContainer>
          <Information>
            <Category><Logo src={logo}/></Category>
            <Category>
              <CategoryText type="contact">CONTACT</CategoryText>
              <CategoryItems>
                <TextContainer>
                  <Mail href="mailto: contact@cerberegames.com">
                    Mail : contact@cerberegames.com
                  </Mail>
                </TextContainer>
                <IconContainer>
                  <Icon
                    onClick={(e) =>
                      handleClick(
                        'https://discord.gg/Wu5bASdBre'
                      )
                    }
                  >
                    <FaDiscord fontSize="inherit" />
                  </Icon>
                  <Icon
                    onClick={(e) =>
                      handleClick(
                        'https://www.youtube.com/channel/UCJrO9HKRgfuucGNQMZXkvRA/videos'
                      )
                    }
                  >
                    <FaYoutube fontSize="inherit" />
                  </Icon>
                </IconContainer>
              </CategoryItems>
            </Category>
            <Category canHide={true}>
              <CategoryTitle onClick={() => setIsAboutHidden(!isAboutHidden)}>
                <CategoryText>CERBERE GAMES</CategoryText>
                <ShowCategory>
                  <MdAdd fontSize="inherit" />
                </ShowCategory>
              </CategoryTitle>
              <CategoryItems isHidden={isAboutHidden}>
                <TextContainer>
                  <Link
                    onClick={(e) =>
                      navigate(`/${lang}/${t('path.about')}`)
                    }
                  >
                    {t('about')}
                  </Link>
                </TextContainer>
                <TextContainer>
                  <Link
                    onClick={(e) =>
                      navigate(`/${lang}/${t(
                        'games'
                      ).toLowerCase()}/jewel-run`)
                    }
                  >
                    Jewel Run
                  </Link>
                </TextContainer>
                <TextContainer>
                  <Link
                    onClick={(e) =>
                      navigate(`/${lang}/${t(
                        'games'
                      ).toLowerCase()}/kalulu`)
                    }
                  >
                    Kalulu
                  </Link>
                </TextContainer>
              </CategoryItems>
            </Category>
          </Information>
        </ContentContainer>
      </FooterContainer>
    </Container>
  );
};

export default withTranslation()(Footer);

import Path from "components/Path";
import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import bulletMainImage from 'img/bullet-main.png';
import bulletFireworksImage from 'img/bullet-youngjamove.gif';
import bulletTreeImage from 'img/bullet-applypattern.gif';
import bulletTackleImage from 'img/bullet-bossmode.gif';
import bulletGraphicsImage from 'img/bullet-adelheid.png';
import level99logo from 'img/bullet-level99logo.png'
import boardgameImage from 'img/bullet-boardgame.webp'

const Container = styled.div``;

const PresentationImage = styled.div`
  background-image: url(${bulletMainImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  height: 1420px;
  margin-top: 20px;
  text-align: center;
  @media only screen and (max-width: 1100px) {
    height: 500px;
  }
  @media only screen and (max-width: 730px) {
    height: 400px;
  }
  @media only screen and (max-width: 580px) {
    height: 220px;
  }
`;

const Line = styled.hr`
  border: 1px solid #FAF5D8;
  margin-bottom: 40px;

`;

const MultiplayerPresentation = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SteamLink = styled.span`
  font-size: 30px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const MultiplayerImage = styled.img`
  display: block;
  width: 40%;
`;

const Title = styled.div`
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
  @media only screen and (max-width: 580px) {
    font-size: 18px;
  }
  text-align: center;
`;

const JewelRunContainer = styled.div`
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
  display: flex;
  align-items: center;
  justify-content: center;
`;

const JewelRunImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 40%;
  max-height: 550px;
  @media only screen and (max-width: 900px) {
    width: 80%;
    margin-left: 0px;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Description = styled.div`
  margin: 0px 10% 0px 10%;
  font-size: 18px;
  @media only screen and (max-width: 580px) {
    font-size: 12px;
  }
`;

const List = styled.ul`
  padding-left: 0px;
  text-align: center;
  list-style-type: none;
`;

const Element = styled.li`
  text-align: center;
  padding: 5px;
`;

const GraphicsContainer = styled.div`
  margin-bottom: 40px;
  @media only screen and (max-width: 900px) {
    text-align: center;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GraphicsImage = styled.img`
  object-fit: contain;
  object-position: left;
  width: 45%;
  max-height: 550px;
  @media only screen and (max-width: 900px) {
    width: 80%;
  }
`;

const handleClick = (link) => {
  window.open(link, '_blank');
};

const Bullet = ({ t, i18n }) => {
  return (
    <Container>
      <Path
        segments={[{ name: 'Home', path: '/' }]}
        lastSegment="Jewel Run"
      />
      <PresentationImage />
      <Line/>
      <SteamLink
        onClick={(e) =>
          handleClick(
            'https://store.steampowered.com/app/2830200/Bullet/'
          )
        }
      >
      {t('bullet_steam_link')}
      </SteamLink>
      <SteamLink
        onClick={(e) =>
          handleClick(
            'https://drive.google.com/drive/folders/16U-UnI6mMMLEp9W1dvVEm-9oLxpt-NVp?usp=drive_link'
          )
        }
      >
      {t('bullet_press_kit_link')}
      </SteamLink>
      <JewelRunContainer>
        <JewelRunImage src={bulletTreeImage}></JewelRunImage>
      </JewelRunContainer>
      <DescriptionContainer>
        <Title>{t('bullet_about_title')}</Title>
        <Description>
          <List>
            <Element>{t('bullet_about_1')}</Element>
            <Element>{t('bullet_about_2')}</Element>
            <Element>{t('bullet_about_3')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <JewelRunContainer>
        <JewelRunImage src={bulletFireworksImage}></JewelRunImage>
      </JewelRunContainer>
      <DescriptionContainer>
        <Title>{t('bullet_fun_title')}</Title>
        <Description>
          <List>
            <Element>{t('bullet_fun_1')}</Element>
            <Element>{t('bullet_fun_2')}</Element>
            <Element>{t('bullet_fun_3')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <GraphicsContainer>
        <JewelRunImage src={bulletTackleImage}></JewelRunImage>
      </GraphicsContainer>
      <DescriptionContainer>
        <Title>{t('bullet_boardgame_title')}</Title>
        <Description>
          <List>
            <Element>{t('bullet_boardgame_1')}</Element>
            <Element>{t('bullet_boardgame_2')}</Element>
          </List>
        </Description>
      </DescriptionContainer>
      <JewelRunContainer>
        <JewelRunImage src={boardgameImage}></JewelRunImage>
      </JewelRunContainer>
    </Container>
  );
}

export default withTranslation()(Bullet);

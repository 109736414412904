import { useNavigate } from 'react-router';
import styled from 'styled-components';
import i18n from 'i18next';
import Footer from '../Footer';
import Navbar from 'components/navbar/Navbar';
import { Top } from 'components/Top';
import { BUTTON_COLOR, BUTTON_HOVER_COLOR, DARK_SCHEME_COLOR, DARK_SCHEME_COLOR_BACKGROUND } from 'utility/css/values';

const Container = styled.div`
  @media (prefers-color-scheme: dark) {
    background-color: ${DARK_SCHEME_COLOR_BACKGROUND};
    color: ${DARK_SCHEME_COLOR};
  }
`;

const NotFoundContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  margin-bottom: 20px;
  font-size: 15px;
  color: grey;
`;

const ImageWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 600px;
  max-width: 90%;
`;

const Image = styled.div`
  background-color: #8D352E;
  width: 100%;
  height: 400px;
  color: white;
  font-size: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  margin-top: 20px;
  background-color: ${BUTTON_COLOR};
  padding: 15px 20px 15px 20px;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 300;
  @media (hover: hover) {
    &:hover {
      cursor: pointer;
      background-color: ${BUTTON_HOVER_COLOR};
    }
  }
`;

const ErrorContent = () => {

    const navigate = useNavigate();

    return (
        <Container>
          <Navbar scrolling={true} />
          <Top />
          <NotFoundContainer>
            <Text>{i18n.t('undefined_error')}</Text>
            <ImageWrapper>
              <Image>{i18n.t('oops')}</Image>
            </ImageWrapper>
            <Button onClick={() => navigate('/')}>{i18n.t('go_to_homepage')}</Button>
          </NotFoundContainer>
          <Footer />
        </Container>
      );
}

export default ErrorContent

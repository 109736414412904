import { withTranslation } from "react-i18next";
import styled from 'styled-components';

import kaluluIcon from 'img/KaluluIcon.png';
import kaluluScreen from 'img/Kalulu.png';

const Container = styled.div`
    background-color: #1D2325;
    color: white;
    margin-bottom: 100px;
    padding-bottom: 50px;
`;

const Icon = styled.div`
    position: absolute;
    left: 50%;
    top: 0px;
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    background-image: url(${props => props.backgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
`

const Content = styled.div`
    position: relative;
    padding-top: 50px;
    width: 100%;
`;

const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Title = styled.h1`
    text-align: center;
    font-size: 40px;
    margin-bottom: 40px;
`;

const Description = styled.p`
    text-align: center;
    font-size: 20px;
    margin: auto auto 40px auto;
    width: 90%;
    max-width: 1000px;
`;

const ImageWrapper = styled.div`
    margin: auto;
    text-align: center;
    width: 80%;
    max-width: 700px;
`;

const Image = styled.img`
    width: 100%;
`

const KaluluPresentation = ({ t, i18n }) => {
    return (
        <Container>
            <ContentWrapper>
                <Content>
                    <Icon backgroundImage={kaluluIcon}></Icon>
                    <Title>Kalulu</Title>
                    <Description>
                        {t('kalulu_description_1')} <br/> 
                        {t('kalulu_description_2')}
                    </Description>
                    <ImageWrapper>
                        <Image src={kaluluScreen}></Image>
                    </ImageWrapper>
                </Content>
            </ContentWrapper>
            
        </Container>
    );
}

export default withTranslation()(KaluluPresentation);